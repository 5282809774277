/* eslint-disable no-useless-escape */
/* HOW TO USE ROUTEMAP

    When you need a link to a page in the website, import this function into your
    component like this:

        import RouteMap from '../../../assets/RouteMap'

    To get the url for a regular page, use RouteMap.Pageand pass the english
    string of the page you need.
    For instance, if you need a link to the first job index page:

        RouteMap.Page('first-jobs')

    This function will know which language you are using based on the current url.

    If you need a show for an item, use the RouteMap.Show function:

        RouteMap.Show('first_job', job.url)

    If you need an edit for an item, use the RouteMap.Edit function with the item's id:

        RouteMap.Show('first_job', job.id)

    It works for first_job, internship, student_job, kot, article and company.
    Normally, your item object should contain a url created by the method
    'get_url' in the rails portion of the app.

    IMPORTANT:

    If the page slug in the url is the same for the three different languages,
    the 'default' in the switch takes care of it.

*/

import URLHandler from './URLHandler.js'
import CollectionHandler from './CollectionHandler.js'

const GetLocaleFromUrl = () => {
    return URLHandler.GetLocaleFromUrl()
}

const GetLanguage = (language) => {
    if (language) {
        return language
    } else {
        return GetLocaleFromUrl()
    }
}

const Home = (language = undefined) => {
    return `https://www.student.be/${GetLanguage(language)}/`
}

const Page = (page, language = null, addedSlash = '/') => {
    const lang = GetLanguage(language)
    const baseUrl = `${window.location.protocol}//${window.location.host}/${lang}`
    if (page === '/' || page === '') { return (baseUrl + addedSlash) }
    if (page && page.match(/^(entreprises|bedrijven|companies)\//)) {
        return Show('company', page.split('/')[1], language)
    }
    if (page && page.match(/^(jobs-etudiants|studentenjobs|student-jobs)\//)) {
        return Show('student_job', page.split('/')[1], language)
    }
    if (page && page.match(/^(stages|internships)\//)) {
        return Show('internship', page.split('/')[1], language)
    }
    if (page && page.match(/^(premiers-emplois|eerste-jobs|first-jobs)\//)) {
        return Show('first_job', page.split('/')[1], language)
    }
    if (page && page.match(/^(articles|artikels)\//)) {
        return Show('article', page.split('/')[1], language)
    }
    if (page && page.match(/^(kots-a-louer|student-rooms|koten)\//)) {
        return Show('kot', page.split('/')[1], language)
    }
    return baseUrl + PageLink(page, lang)
}

const PageLink = (page, language = null) => {
    const lang = GetLanguage(language)
    let path = ''
    /* This RegEx checks if the page is a item show page (job or kot) */
    if (page && page.match(/^[^\/]+\/[^\/]+\/\d+$/)) { return '/' }
    /* This RegEx checks if the page is a company show page */
    path = TranslatePage(page, lang)
    return URLHandler.addEndingBackslashWhenMissing(path)
}

const ArticleCategory = (articleCategory, language = null) => {
    const seoCollection = CollectionHandler.Get('articleTagCollection')
    const lang = GetLanguage(language)
    let result = ''
    const selectedTag = seoCollection.find((item) => {
        if (item.value.toLowerCase() === articleCategory.toLowerCase()) {
            return true
        }
    })

    result = `${Page('student-life', language)}categories/${URLHandler.EncodeTagToUrl(selectedTag[lang])}`
    if (result.charAt(result.length - 1) !== '/') {
        result = result + '/'
    }

    return result
}

const Tag = (type, collectionType, value, language = null) => {
    let tagString = ''
    let result = ''
    const lang = GetLanguage(language)

    if (collectionType === 'freeText') {
        tagString = value
    } else {
        const seoCollection = CollectionHandler.Get(collectionType)
        const selectedTag = seoCollection.find((item) => {
            if (['cityCollection', 'seoStudyDomainCollection'].includes(collectionType) && item.value_name === value) {
                return true
            } else if (item.value === value) {
                return true
            }
        })

        let selectedTagString = selectedTag[lang]
        if (!selectedTagString) {
            selectedTagString = selectedTag.name
        }

        tagString = URLHandler.EncodeTagToUrl(selectedTagString)
    }

    switch (type) {
    case 'first_job':
    case 'first-jobs':
        result = Page('/', lang) + tagString + TranslatePage('first-jobs', lang)
        break
    case 'internship': case 'internships':
        result = Page('/', lang) + tagString + TranslatePage('internships', lang)
        break
    case 'student_job': case 'student-jobs':
        result = Page('/', lang) + tagString + TranslatePage('student-jobs', lang)
        break
    case 'kot': case 'student-rooms':
        result = Page('/', lang) + tagString + TranslatePage('student-rooms', lang)
        break
    default:
        result = '#'
    }

    if (result.charAt(result.length - 1) !== '/') {
        result = result + '/'
    }

    return result
}

const TrailingQuickFilterTag = (type, value, collection, language = null) => {
    let tagString = ''
    let result = ''
    const lang = GetLanguage(language)

    const selectedTag = collection.find((item) => {
        if (item.value === value) {
            return true
        }
    })

    tagString = URLHandler.EncodeTagToUrl(selectedTag[lang])

    switch (type) {
    case 'first_job':
    case 'first-jobs':
        result = `${Page('/', lang, '')}${TranslatePage('first-jobs', lang)}/${tagString}/`
        break
    case 'internship':
    case 'internships':
        result = `${Page('/', lang, '')}${TranslatePage('internships', lang)}/${tagString}/`
        break
    case 'student_job':
    case 'student-jobs':
        result = `${Page('/', lang, '')}${TranslatePage('student-jobs', lang)}/${tagString}/`
        break
    case 'company':
    case 'companies':
        result = `${Page('/', lang, '')}${TranslatePage('companies', lang)}/${tagString}/`
        break
    default:
        result = '#'
    }

    if (result.charAt(result.length - 1) !== '/') {
        result = result + '/'
    }

    return result
}

const Show = (type, url, language = null) => {
    let result = ''
    switch (type) {
    case 'first-jobs':
    case 'first_job':
        result = Page('first-jobs', language) + url
        break
    case 'internships':
    case 'internship':
        result = Page('internships', language) + url
        break
    case 'student-jobs':
    case 'student_job':
        result = Page('student-jobs', language) + url
        break
    case 'kots':
    case 'kot':
        result = Page('student-rooms', language) + url
        break
    case 'student-life':
    case 'article':
        result = Page('student-life', language) + url
        break
    case 'companies':
    case 'company':
        result = Page('companies', language) + url
        break
    case 'promotions':
    case 'promotion':
        result = Page('only-for-students', language) + url
        break
    case 'newAd':
        result = Page('employer/new-ad', language) + '?type=' + url
        break
    default:
        result = '#'
    }

    if (result.charAt(result.length - 1) !== '/') {
        result = result + '/'
    }

    return result
}

const LocationIndex = (type, addressObject, locationZip, params = '', language = null) => {
    const lang = GetLanguage(language)
    const cityCollection = CollectionHandler.Get('cityCollection')

    const cityFoundInCollection = cityCollection.find((item) => {
        return item[lang] === addressObject.city
    })
    if (cityFoundInCollection) {
        return Tag(type, 'cityCollection', cityFoundInCollection.value_name)
    }

    const cityFoundInCollectionBasedOnZip = cityCollection.find(location => {
        return parseInt(location.value) === addressObject.zip
    })
    if (cityFoundInCollectionBasedOnZip) {
        return Tag(type, 'cityCollection', cityFoundInCollectionBasedOnZip.value_name)
    }

    return Index(type, params)
}

const Index = (type, params = null, language = null) => {
    let result = ''
    switch (type) {
    case 'first_job':
        result = Page('first-jobs', language)
        break
    case 'internship':
        result = Page('internships', language)
        break
    case 'student_job':
        result = Page('student-jobs', language)
        break
    case 'kot':
        result = Page('student-rooms', language)
        break
    case 'company':
        result = Page('companies', language)
        break
    case 'article':
        result = Page('student-life', language)
        break
    default:
        result = '#'
    }

    if (params) {
        result = `${result}?${params}`
    }

    return result
}

const Edit = (type, id, language = null) => {
    let result = ''
    switch (type) {
    case 'first_job':
        result = Page('/', language) + `users/first_jobs/${id}/edit`
        break
    case 'internship':
        result = Page('/', language) + `users/internships/${id}/edit`
        break
    case 'student_job':
        result = Page('/', language) + `users/student_jobs/${id}/edit`
        break
    case 'kot':
        result = Page('/', language) + `users/kots/${id}/edit`
        break
    case 'article':
        result = Page('/', language) + `users/articles/${id}/edit`
        break
    case 'company':
        result = Page('/', language) + 'users/company/edit'
        break
    default:
        result = '#'
    }

    return result
}

const TranslatePage = (page, lang) => {
    let path = ''
    switch (page) {
    /* FIRST JOBS INDEX */
    case 'first-jobs': // ENGLISH
    case 'premiers-emplois': // FRENCH
    case 'eerste-jobs': // DUTCH
        if (lang === 'en') { path = '/first-jobs' };
        if (lang === 'fr') { path = '/premiers-emplois' };
        if (lang === 'nl') { path = '/eerste-jobs' };
        break
    case 'articles': // ENGLISH & FRENCH
    case 'artikels': // DUTCH
        if (lang === 'en') { path = '/articles' };
        if (lang === 'fr') { path = '/articles' };
        if (lang === 'nl') { path = '/artikels' };
        break
    case 'first-jobs/articles': // ENGLISH & FRENCH
    case 'premiers-emplois/articles': // ENGLISH & FRENCH
    case 'eerste-jobs/articles': // ENGLISH & FRENCH
    case 'first-jobs/artikels': // DUTCH
    case 'premiers-emplois/artikels': // DUTCH
    case 'eerste-jobs/artikels': // DUTCH
        if (lang === 'en') { path = '/first-jobs/articles' };
        if (lang === 'fr') { path = '/premiers-emplois/articles' };
        if (lang === 'nl') { path = '/eerste-jobs/artikels' };
        break
    case 'first-jobs/companies': // ENGLISH
    case 'premiers-emplois/companies': // ENGLISH
    case 'eerste-jobs/companies': // ENGLISH
    case 'first-jobs/entreprises': // FRENCH
    case 'premiers-emplois/entreprises': // FRENCH
    case 'eerste-jobs/entreprises': // FRENCH
    case 'first-jobs/bedrijven': // DUTCH
    case 'premiers-emplois/bedrijven': // DUTCH
    case 'eerste-jobs/bedrijven': // DUTCH
        if (lang === 'en') { path = '/first-jobs/companies' };
        if (lang === 'fr') { path = '/premiers-emplois/entreprises' };
        if (lang === 'nl') { path = '/eerste-jobs/bedrijven' };
        break
    case 'companies': // ENGLISH
    case 'entreprises': // FRENCH
    case 'bedrijven': // DUTCH
        if (lang === 'en') { path = '/companies' };
        if (lang === 'fr') { path = '/entreprises' };
        if (lang === 'nl') { path = '/bedrijven' };
        break
    case 'events': // ENGLISH
    case 'evenements': // FRENCH
    case 'evenementen': // DUTCH
        if (lang === 'en') { path = '/events' };
        if (lang === 'fr') { path = '/evenements' };
        if (lang === 'nl') { path = '/evenementen' };
        break
    case 'first-jobs/events': // ENGLISH
    case 'premiers-emplois/events': // ENGLISH
    case 'eerste-jobs/events': // ENGLISH
    case 'first-jobs/evenements': // FRENCH
    case 'premiers-emplois/evenements': // FRENCH
    case 'eerste-jobs/evenements': // FRENCH
    case 'first-jobs/evenementen': // DUTCH
    case 'premiers-emplois/evenementen': // DUTCH
    case 'eerste-jobs/evenementen': // DUTCH
        if (lang === 'en') { path = '/first-jobs/events' };
        if (lang === 'fr') { path = '/premiers-emplois/evenements' };
        if (lang === 'nl') { path = '/eerste-jobs/evenementen' };
        break

        /* INTERNSHIP INDEX */
    case 'internships': // ENGLISH
    case 'stages': // FRENCH and DUTCH
        if (lang === 'en') { path = '/internships' };
        if (lang === 'fr') { path = '/stages' };
        if (lang === 'nl') { path = '/stages' };
        break
    case 'internships/articles': // ENGLISH
    case 'internships/artikels': // ENGLISH
    case 'stages/articles': // FRENCH
    case 'stages/artikels': // DUTCH
        if (lang === 'en') { path = '/internships/articles' };
        if (lang === 'fr') { path = '/stages/articles' };
        if (lang === 'nl') { path = '/stages/artikels' };
        break

        /* STUDENT JOB INDEX */
    case 'student-jobs': // ENGLISH
    case 'jobs-etudiants': // FRENCH
    case 'studentenjobs': // DUTCH
        if (lang === 'en') { path = '/student-jobs' };
        if (lang === 'fr') { path = '/jobs-etudiants' };
        if (lang === 'nl') { path = '/studentenjobs' };
        break
    case 'student-jobs/articles': // ENGLISH
    case 'student-jobs/artikels': // ENGLISH
    case 'jobs-etudiants/articles': // FRENCH
    case 'jobs-etudiants/artikels': // FRENCH
    case 'studentenjobs/artikels': // DUTCH
    case 'studentenjobs/articles': // DUTCH
        if (lang === 'en') { path = '/student-jobs/articles' };
        if (lang === 'fr') { path = '/jobs-etudiants/articles' };
        if (lang === 'nl') { path = '/studentenjobs/artikels' };
        break

        /* KOT INDEX */
    case 'student-rooms': // ENGLISH
    case 'kots-a-louer': // FRENCH
    case 'koten': // DUTCH
        if (lang === 'en') { path = '/student-rooms' };
        if (lang === 'fr') { path = '/kots-a-louer' };
        if (lang === 'nl') { path = '/koten' };
        break
    case 'student-rooms/articles': // ENGLISH
    case 'student-rooms/artikels': // ENGLISH
    case 'kots-a-louer/articles': // FRENCH
    case 'kots-a-louer/artikels': // FRENCH
    case 'koten/articles': // DUTCH
    case 'koten/artikels': // DUTCH
        if (lang === 'en') { path = '/student-rooms/articles' };
        if (lang === 'fr') { path = '/kots-a-louer/articles' };
        if (lang === 'nl') { path = '/koten/artikels' };
        break

        /* ARTICLE INDEX */
    case 'student-life': // ENGLISH, FRENCH AND DUTCH
        path = '/student-life'
        break

        /* ARTICLE INDEX */
    case 'student-life/categories/education': // ENGLISH
    case 'student-life/categories/etudes-et-formations': // FRENCH
    case 'student-life/categories/studiekeuze-en-opleidingen': // DUTCH        if (lang === 'en') { path = '/student-rooms/articles' };
        if (lang === 'en') { path = '/student-life/categories/education' };
        if (lang === 'fr') { path = '/student-life/categories/etudes-et-formations' };
        if (lang === 'nl') { path = '/student-life/categories/studiekeuze-en-opleidingen' };
        break

        /* ABOUT US */
    case 'about': // ENGLISH
    case 'about-us': // ENGLISH
    case 'a-propos': // FRENCH
    case 'over-ons': // DUTCH
        if (lang === 'en') { path = '/about-us' };
        if (lang === 'fr') { path = '/a-propos' };
        if (lang === 'nl') { path = '/over-ons' };
        break

        /* ABOUT FIRST JOB */
    case 'starter-job': // ENGLISH and DUTCH
    case 'jeune-diplomé': // FRENCH
    case 'jeune-diplom%C3%A9': // FRENCH
        if (lang === 'en') { path = '/starter-job' };
        if (lang === 'fr') { path = '/jeune-diplomé' };
        if (lang === 'nl') { path = '/starter-job' };
        break

        /* ABOUT INTERNSHIP */
    case 'internship': // ENGLISH
    case 'stage': // FRENCH and DUTCH
        if (lang === 'en') { path = '/internship' };
        if (lang === 'fr') { path = '/stage' };
        if (lang === 'nl') { path = '/stage' };
        break

        /* ABOUT STUDENT JOB */
    case 'student-job': // ENGLISH
    case 'job-etudiant': // FRENCH
    case 'studentenjob': // DUTCH
        if (lang === 'en') { path = '/student-job' };
        if (lang === 'fr') { path = '/job-etudiant' };
        if (lang === 'nl') { path = '/studentenjob' };
        break

        /* PUBLISH A STUDENT JOB */
    case 'publish-a-student-job': // ENGLISH
    case 'publier-un-job-etudiant': // FRENCH
    case 'publiceer-een-studentenjob': // DUTCH
        if (lang === 'en') { path = '/publish-a-student-job' };
        if (lang === 'fr') { path = '/publier-un-job-etudiant' };
        if (lang === 'nl') { path = '/publiceer-een-studentenjob' };
        break

        /* ABOUT SUMMER JOB */
    case 'summer-job': // ENGLISH
    case 'job-été': // FRENCH
    case 'job-%C3%A9t%C3%A9': // FRENCH
    case 'vakantie-job': // DUTCH
        if (lang === 'en') { path = '/summer-job' };
        if (lang === 'fr') { path = '/job-été' };
        if (lang === 'nl') { path = '/vakantie-job' };
        break

        /* ABOUT KOT */
    case 'student-room': // ENGLISH
    case 'kot': // FRENCH and DUTCH
        if (lang === 'en') { path = '/student-room' };
        if (lang === 'fr') { path = '/kot' };
        if (lang === 'nl') { path = '/kot' };
        break

        /* ABOUT PRESS */
    case 'press': // ENGLISH
    case 'presse': // FRENCH
    case 'pers': // DUTCH
        if (lang === 'en') { path = '/press' };
        if (lang === 'fr') { path = '/presse' };
        if (lang === 'nl') { path = '/pers' };
        break

        /* TERMS */
    case 'terms': // ENGLISH
    case 'terms-and-privacy': // ENGLISH
    case 'conditions-generales-et-respect-de-la-vie-privee': // FRENCH
    case 'algemene-voorwaarden-en-privacybeleid': // DUTCH
        if (lang === 'en') { path = '/terms-and-privacy' };
        if (lang === 'fr') { path = '/conditions-generales-et-respect-de-la-vie-privee' };
        if (lang === 'nl') { path = '/algemene-voorwaarden-en-privacybeleid' };
        break

        /* KOT OWNERS INFO AND CONDITIONS */
    case 'kot-owners': // ENGLISH
    case 'propriétaires': // FRENCH
    case 'propri%C3%A9taires': // FRENCH
    case 'uw-kot-verhuren': // DUTCH
        if (lang === 'en') { path = '/kot-owners' };
        if (lang === 'fr') { path = '/propriétaires' };
        if (lang === 'nl') { path = '/uw-kot-verhuren' };
        break

        /* HOME EMPLOYER */
    case 'employers': // ENGLISH
    case 'employeurs': // FRENCH
    case 'werkgevers': // DUTCH
        if (lang === 'en') { path = '/employers' };
        if (lang === 'fr') { path = '/employeurs' };
        if (lang === 'nl') { path = '/werkgevers' };
        break
    case 'employers/student-job': // ENGLISH
    case 'employeurs/job-etudiant': // FRENCH
    case 'werkgevers/studentenjob': // DUTCH
        if (lang === 'en') { path = '/employers/student-job' };
        if (lang === 'fr') { path = '/employeurs/job-etudiant' };
        if (lang === 'nl') { path = '/werkgevers/studentenjob' };
        break

    default:
        path = '/' + page
        break
    }
    return path
}

export default { Page, PageLink, Tag, TrailingQuickFilterTag, ArticleCategory, Show, Edit, GetLocaleFromUrl, Home, Index, LocationIndex, TranslatePage }
