/* eslint-disable react/no-unescaped-entities */
import React, { useState, useRef, useContext, useEffect } from 'react'
import { Widget } from '@typeform/embed-react'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './HomeMyBS_style'
import {
    Container,
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Hidden,
    Button
} from '@material-ui/core'

import { MY_BS_REGISTRATION } from 'assets/AuthentificationHelper'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import RouteMapLink from 'shared/wrappers/RouteMapLink/RouteMapLink'
import { UserContext } from 'contexts/UserContext'
import { LoginRegisterContext } from 'contexts/LoginRegisterContext'
import URLParamsHandler from 'assets/URLParamsHandler'
import { optimiseSizeForMobile, MEDIUM_SIZE, LARGER_SIZE } from 'assets/CloudinaryURLHelper'

const TYPEFORM_ID = 'UV442qN4'

const HomeMyBS = () => {
    const classes = useStyles()
    const [startRegistration, setStartRegistration] = useState(false)
    const typeformWidgetRef = useRef(null)
    const loginRegisterContext = useContext(LoginRegisterContext)
    const userContext = useContext(UserContext)

    const businessSchools = [
        { imageKey: 'hec', url: 'https://www.hec.edu/en' },
        { imageKey: 'Hult_International_Business_School_3e3154', url: 'https://www.hult.edu/' },
        { imageKey: 'essec_logo', url: 'https://www.essec.edu/en/' },
        { imageKey: 'AMS_Antwerp_Management_School', url: 'https://www.antwerpmanagementschool.be/en' },
        { imageKey: 'iese_logo', url: 'https://www.iese.edu/' },
        { imageKey: 'eada', url: 'https://www.eada.edu/en' },
        { imageKey: 'Esade_Business_School', url: 'https://www.esade.edu/en' },
        { imageKey: 'Ie-Business-School-logo', url: 'https://www.ie.edu/business-school/' },
        { imageKey: 'Insead', url: 'https://www.insead.edu/' },
        { imageKey: 'Vlerick_Business_School', url: 'https://www.vlerick.com/en/' },
        { imageKey: 'ESCP_Business_School', url: 'https://www.escp.eu/' },
        { imageKey: 'edhec_logo', url: 'https://www.edhec.edu/en' },
        { imageKey: 'skema_logo', url: 'https://www.skema.edu/' },
        { imageKey: 'Bocconi_University', url: 'https://www.unibocconi.it/en' },
        { imageKey: 'kedgebs_logo', url: 'https://student.kedge.edu/' },
        { imageKey: 'Solvay_Brussels_School_of_Economics_and_Management', url: 'https://www.solvay.edu/en/' }
    ]

    useEffect(() => {
        const registrationProcess = URLParamsHandler.loadProcessFromURL()
        if (registrationProcess === MY_BS_REGISTRATION) {
            setTimeout(() => openTypeform(), 1000)
        }
    }, [])

    const openLoginRegisterModal = () => {
        loginRegisterContext.openLoginRegisterModal({
            origin: MY_BS_REGISTRATION,
            afterAuthAction: afterAuthAction
        })
    }

    const afterAuthAction = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: MY_BS_REGISTRATION })
    }

    const handleRegistrationClick = () => {
        if (userContext?.user) {
            openTypeform()
        } else {
            openLoginRegisterModal()
        }
    }

    const openTypeform = () => {
        setStartRegistration(true)
        typeformWidgetRef.current.scrollIntoView()
    }

    const renderSchool = ({ imageKey, url }) => {
        return (
            <Grid item xs={6} sm={4} className={classes.partnerImageBox}>
                <RouteMapLink redirectionUrl={url} target='blank'>
                    <Image
                        className={classes.partnerImage}
                        cloudName="studentbe"
                        publicId={`my_bs/${imageKey}`}
                        alt={`my BS busines school ${imageKey}`}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width={MEDIUM_SIZE} quality="auto:best" crop="fill" />
                    </Image>
                </RouteMapLink>
            </Grid>
        )
    }

    return (
        <Container maxWidth='md' >
            <Box className={classes.scheduleContainer}>
                <Grid container>
                    <Grid item xs={12} className={classes.imageContainer}>
                        <Image
                            cloudName="studentbe"
                            publicId='my_bs/mybs_2024_banner'
                            alt='My Business School Event'
                            className={classes.imageStyle}
                        >
                            <Transformation
                                flags="progressive"
                                fetchFormat="auto"
                                quality="auto:good"
                                width={optimiseSizeForMobile(LARGER_SIZE)}
                                crop="scale"
                            />
                        </Image>
                    </Grid>
                    <Box className={classes.keyInfoContainer}>
                        <Box className={classes.keyInfoLeftSideContainer}>
                            <Box className={classes.keyInfoLeftSide}>
                                <Typography component='h1' className={classes.titleEvent}>
                                    myBS Event
                                </Typography>
                                <Typography variant='body1' className={classes.fontSize16}>
                                    The <span className={classes.spanBold}>myBS</span> event is returning to <span className={classes.spanBold}>Leuven</span> and <span className={classes.spanBold}>Ghent</span>, and it’s bigger than ever!
                                    Whether you are eyeing those top business schools or are just curious,
                                    this event is your chance to learn about their <span className={classes.spanBold}>programs</span>,
                                    <span className={classes.spanBold}>get insider tips on applications</span>,
                                    and discover how to secure those scholarships.
                                </Typography>
                                <Box className={classes.registrationButtonBox}>
                                    <Button
                                        className={classes.registrationButton}
                                        color='secondary'
                                        onClick={handleRegistrationClick}
                                    >
                                        I am in ... sign me up!
                                    </Button>
                                </Box>
                            </Box>
                            <Box className={classes.datesInfo}>
                                <Box className={classes.infoTitle}>
                                    <Typography variant='h1' component='h2'>
                                        📅 Dates & schedule
                                    </Typography>
                                </Box>
                                <Box className={classes.infoText}>
                                    <Typography variant='h3' className={classes.subListTitle}>
                                        5th of November in Leuven:
                                    </Typography>
                                    <Typography variant='body1' className={`${classes.subListLeft} ${classes.fontSize16}`}>
                                        - 12h-16h Get-to-knows at Zaal Couvreur, Agora
                                    </Typography>
                                    <Typography variant='body1' className={`${classes.subListLeft} ${classes.fontSize16}`}>
                                        - 16h-17h Presentation of the business schools at Auditorium Emma Vorlat, Agora
                                    </Typography>
                                    <Typography variant='body1' className={`${classes.subListLeft} ${classes.fontSize16}`}>
                                        - 17h-19h Open Fair (no registration needed) at Zaal Couvreur, Agora
                                    </Typography>
                                    <Typography variant='h3' className={classes.subListTitle}>
                                        6th of November in Ghent:
                                    </Typography>
                                    <Typography variant='body1' className={`${classes.subListLeft} ${classes.fontSize16}`}>
                                        - 12h-16h Get-to-knows at Foyer, Campus Tweekerken
                                    </Typography>
                                    <Typography variant='body1' className={`${classes.subListLeft} ${classes.fontSize16}`}>
                                        - 16h-17h Presentation of the business schools at Auditorium Maurice Van Vaerenbergh, Hoveniersberg
                                    </Typography>
                                    <Typography variant='body1' className={`${classes.subListLeft} ${classes.fontSize16}`}>
                                        - 17h-19h Open Fair (no registration needed) at Peristilium, Hoveniersberg
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.keyInfoRightSide}>
                            <Hidden smDown>
                                <Image
                                    className={classes.introImage}
                                    cloudName="studentbe"
                                    publicId='my_bs/business_school.png'
                                    alt='busines school image'
                                >
                                    <Transformation flags="progressive" fetchFormat="auto" width={MEDIUM_SIZE} quality="auto:best" crop="fill" />
                                </Image>
                            </Hidden>
                        </Box>
                    </Box>
                    <Box className={classes.partnersContainer}>
                        <Box className={classes.partnersBanner}>
                            <Typography variant='h1' component='h2'>
                                Here's the tea....
                            </Typography>
                            <Typography variant='body1' className={classes.fontSize16}>
                                Let's get to know who you can meet during the fair:
                            </Typography>
                        </Box>
                        <Box className={classes.partnersList}>
                            <Grid container spacing={2} className={classes.partnerBox}>
                                {businessSchools.map(school => renderSchool(school))}
                            </Grid>
                            <Button
                                className={classes.registrationButton}
                                color='secondary'
                                onClick={handleRegistrationClick}
                            >
                                Register for the event
                            </Button>
                        </Box>
                    </Box>
                    <Box className={classes.extraInfoContainer}>
                        <Box className={classes.infoTitle}>
                            <Typography variant='h1' component='h2'>
                                What to expect?
                            </Typography>
                        </Box>
                        <Box my={1}>
                            <Typography variant='body1' className={classes.fontSize16}>
                                <span className={classes.spanBold}>Get-to-Know Interviews: </span>
                                This will provide a unique opportunity for you to meet with the business school(s) you are interested in on a personal level
                                and find out more about their courses,
                                get advice on how to apply and learn about scholarship opportunities.
                                From 12 to 4 PM.
                            </Typography>
                        </Box>
                        <Box my={1}>
                            <Typography variant='body1' className={classes.fontSize16}>
                                <span className={classes.spanBold}>Presentation of the business schools: </span>
                                    Each participating school will have a dedicated slot to present themselves.
                                    This is a fantastic chance to discover what sets the schools apart from each other and what makes them unique in the educational landscape.
                                    From 4 to 5 PM.
                            </Typography>
                        </Box>
                    </Box>
                    <Grid item xs={12} ref={typeformWidgetRef} className={classes.typeformWidgetContainer}>
                        {startRegistration &&
                            <Widget
                                className={classes.typeformWidget}
                                id={TYPEFORM_ID}
                                hidden={{
                                    email: userContext.user.email,
                                    name: `${userContext.user.first_name} ${userContext.user.last_name}`,
                                    user_id: userContext.user.id
                                }}
                            />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box className={classes.infoTitle}>
                                    <Typography variant='h1' component='h2'>
                                        Questions about the event
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion bgColor='primary'>
                                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                                        <Typography color='primary' variant='body1' className={classes.fontSize16}>
                                            What should I wear during the event?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            There is no official dress code, but it is recommanded to wear something business casual. That way, you will make a good first impression 💪.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                                        <Typography variant='body1' className={classes.fontSize16}>
                                            Should I prepare something for the Get-to-Knows?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            No stress 😅 ! It's in the name ... You’ll just “get to know” the schools. To prepare yourself you can take a look on their website. Maybe you have a general question about their program? Or you have a more specific question concerning the scholarships? It’s recommended to bring your cv.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                                        <Typography variant='body1' className={classes.fontSize16}>
                                            What if I'm not sure yet about my business school?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            If you are hesitating between schools it’s recommended to make an appointment with all schools you are interested in. That way, it will be easier to compare 🧐.
                                            In case you aren’t even sure about studying at a business school at all, it’s highly advised to follow the presentation, from 16h until 17h.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                                        <Typography variant='body1' className={classes.fontSize16}>
                                            How will the presentation at 16h look like?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            First of all, all schools will present themselves briefly. Afterwards there is a panel discussion between the schools where you’ll be able to ask some questions 💡.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' component='div'>
                                    💌 If none of the above has answered your question, please contact margaux@student.be
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default HomeMyBS
