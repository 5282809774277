import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import ArticleRouter from './pages/ArticleRouter'
import CompanyRouter from './pages/CompanyRouter'
import FirstJobRouter from './pages/FirstJobRouter'
import InternshipRouter from './pages/InternshipRouter'
import KotRouter from './pages/KotRouter'
import StudentJobRouter from './pages/StudentJobRouter'

import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMap from 'assets/RouteMap'
import URLHandler from 'assets/URLHandler'

import PageWrapper from 'wrappers/PageWrapper/PageWrapper'

import css from './itemnavigation.scss'
import RegexHelper from '../../assets/RegexHelper'

const IndexRouter = (props) => {
    const [paramsForUrlTranslation, setParamsForUrlTranslationParams] = useState(window.location.search)
    const indexRouteCollection = CollectionHandler.Get('indexRouteCollection')
    const pathname = window.location.pathname.replace(/%C3%A8/g, 'è').replace(/%C3%A9/g, 'é').replace(/\_/g, '-')

    const refreshParamsForUrlTranslation = () => setParamsForUrlTranslationParams(window.location.search)

    const urlArray = pathname.slice(1).split('/')
    const lang = urlArray[0]
    const showSlug = urlArray[2]
    const path = pathname.match(/^\/[^\/]{2}\/([^\/]*)/)[1]

    // Test that only the News/blog Indexes are using DarkMode
    const darkMode = RegexHelper.indexNewsURLRegex.test(window.location.href)

    let selectedString = ''
    let trailingSelectedString = ''
    if (indexRouteCollection.includes(urlArray[2])) {
        selectedString = urlArray[1]
    }
    if (indexRouteCollection.includes(urlArray[1])) {
        trailingSelectedString = urlArray[2]
    }

    if (urlArray[1] === 'student-life' && urlArray[2] === 'categories') {
        selectedString = urlArray[3]
    }

    const getSelectedTagObject = () => {
        let selectedTag = null
        let seoCollection = null

        if (trailingSelectedString) {
            seoCollection = CollectionHandler.Get('quickFilterCollection')
            selectedTag = {
                ...seoCollection[props.page].find((item) => {
                    const sanitizedItemName = URLHandler.EncodeTagToUrl(item.name)
                    const sanitizedTrailingSelectedString = URLHandler.EncodeTagToUrl(trailingSelectedString.toLowerCase())
                    return sanitizedItemName === sanitizedTrailingSelectedString
                })
            } // '...' clones the object to avoid error in RouteMap
            if (selectedTag.name) {
                selectedTag.type = 'quickFilter'
                return selectedTag
            } else {
                seoCollection = CollectionHandler.Get('sectorCollection')
                selectedTag = {
                    ...seoCollection.find((item) => {
                        const sanitizedItemName = URLHandler.EncodeTagToUrl(item.name)
                        const sanitizedTrailingSelectedString = URLHandler.EncodeTagToUrl(trailingSelectedString.toLowerCase())
                        return sanitizedItemName === sanitizedTrailingSelectedString
                    })
                } // '...' clones the object to avoid error in RouteMap
                if (selectedTag.name) {
                    selectedTag.type = 'sector'
                    return selectedTag
                } else {
                    return null
                }
            }
        }

        if (!selectedString) return null

        seoCollection = (
            props.page === 'student-life'
                ? CollectionHandler.Get('articleTagCollection')
                : CollectionHandler.Get('cityCollection')
        )

        selectedTag = seoCollection.find((item) => {
            return URLHandler.EncodeTagToUrl(item.name) === selectedString.toLowerCase()
        })
        if (selectedTag) {
            selectedTag.type = 'location'
            if (props.page === 'student-life') {
                selectedTag.type = 'category'
            }
            return selectedTag
        }

        seoCollection = CollectionHandler.Get('seoStudyDomainCollection')
        selectedTag = seoCollection.find((item) => {
            const itemName = URLHandler.EncodeTagToUrl(item.name)
            return itemName === selectedString.toLowerCase()
        })
        if (selectedTag) {
            selectedTag.type = 'studyDomain'
            return selectedTag
        }

        selectedTag = {
            value: selectedString,
            name: selectedString,
            type: 'freeText'
        }
        return selectedTag
    }

    const selectedTag = getSelectedTagObject()

    let altLangUrls
    if (selectedTag && urlArray[1] === 'student-life' && urlArray[2] === 'categories') {
        altLangUrls = {
            current: window.location.href,
            fr: RouteMap.ArticleCategory(selectedTag.value, 'fr'),
            en: RouteMap.ArticleCategory(selectedTag.value, 'en'),
            nl: RouteMap.ArticleCategory(selectedTag.value, 'nl')
        }
    } else if (selectedTag?.type === 'location') {
        altLangUrls = {
            current: window.location.href,
            fr: RouteMap.Tag(props.page, 'cityCollection', selectedTag.value_name, 'fr'),
            en: RouteMap.Tag(props.page, 'cityCollection', selectedTag.value_name, 'en'),
            nl: RouteMap.Tag(props.page, 'cityCollection', selectedTag.value_name, 'nl')
        }
    } else if (selectedTag?.type === 'studyDomain') {
        altLangUrls = {
            current: window.location.href,
            fr: RouteMap.Tag(props.page, 'seoStudyDomainCollection', selectedTag.value_name, 'fr'),
            en: RouteMap.Tag(props.page, 'seoStudyDomainCollection', selectedTag.value_name, 'en'),
            nl: RouteMap.Tag(props.page, 'seoStudyDomainCollection', selectedTag.value_name, 'nl')
        }
    } else if (selectedTag?.type === 'freeText') {
        altLangUrls = {
            current: window.location.href,
            fr: RouteMap.Tag(props.page, 'freeText', selectedTag.value, 'fr'),
            en: RouteMap.Tag(props.page, 'freeText', selectedTag.value, 'en'),
            nl: RouteMap.Tag(props.page, 'freeText', selectedTag.value, 'nl')
        }
    } else if (selectedTag?.type === 'quickFilter') {
        const collection = CollectionHandler.Get('quickFilterCollection')
        altLangUrls = {
            current: window.location.href,
            fr: RouteMap.TrailingQuickFilterTag(props.page, selectedTag.value, collection[props.page], 'fr'),
            en: RouteMap.TrailingQuickFilterTag(props.page, selectedTag.value, collection[props.page], 'en'),
            nl: RouteMap.TrailingQuickFilterTag(props.page, selectedTag.value, collection[props.page], 'nl')
        }
    } else if (selectedTag?.type === 'sector') {
        const collection = CollectionHandler.Get('fullSectorCollection')

        altLangUrls = {
            current: window.location.href,
            fr: RouteMap.TrailingQuickFilterTag(props.page, selectedTag.value, collection, 'fr'),
            en: RouteMap.TrailingQuickFilterTag(props.page, selectedTag.value, collection, 'en'),
            nl: RouteMap.TrailingQuickFilterTag(props.page, selectedTag.value, collection, 'nl')
        }
    } else if (showSlug) {
        altLangUrls = {
            current: window.location.href,
            fr: RouteMap.Show(props.page, showSlug, 'fr'),
            en: RouteMap.Show(props.page, showSlug, 'en'),
            nl: RouteMap.Show(props.page, showSlug, 'nl')
        }
    } else {
        altLangUrls = {
            current: window.location.href,
            fr: RouteMap.Page(props.page, 'fr'),
            en: RouteMap.Page(props.page, 'en'),
            nl: RouteMap.Page(props.page, 'nl')
        }
    }

    return (
        <PageWrapper user={props.user} page={props.page} altLangUrls={altLangUrls} selectedArticleTagObject={selectedTag} paramsForUrlTranslation={paramsForUrlTranslation} darkMode={darkMode}>
            <Helmet>
                <meta name="robots" content="index"/>
                <meta property="og:url" content={`https://www.student.be${pathname}`} />
                <meta property="og:image:alt" content={CloudinaryURLHelper.getImageUrl('META_IMAGE', 'assets/hello-future-lets-talk-student-be')} />
                <meta property="og:site_name" content="Student.be"/>
            </Helmet>
            {props.page === 'first-jobs' &&
                <FirstJobRouter
                    {...props}
                    key='FirstJobRouter'
                    selectedLocationTag={selectedTag}
                    refreshParamsForUrlTranslation={refreshParamsForUrlTranslation}
                    pathname={pathname}
                    lang={lang}
                    path={path}
                    altLangUrls={altLangUrls}
                />
            }
            {props.page === 'companies' &&
                <CompanyRouter
                    {...props}
                    key='CompanyRouter'
                    selectedLocationTag={selectedTag}
                    refreshParamsForUrlTranslation={refreshParamsForUrlTranslation}
                    pathname={pathname}
                    lang={lang}
                    path={path}
                    altLangUrls={altLangUrls}
                />
            }
            {props.page === 'internships' &&
                <InternshipRouter
                    {...props}
                    key='InternshipRouter'
                    selectedLocationTag={selectedTag}
                    refreshParamsForUrlTranslation={refreshParamsForUrlTranslation}
                    pathname={pathname}
                    lang={lang}
                    path={path}
                    altLangUrls={altLangUrls}

                />
            }
            {props.page === 'student-jobs' &&
                <StudentJobRouter
                    {...props}
                    key='StudentJobRouter'
                    selectedLocationTag={selectedTag}
                    refreshParamsForUrlTranslation={refreshParamsForUrlTranslation}
                    pathname={pathname}
                    lang={lang}
                    path={path}
                    altLangUrls={altLangUrls}

                />
            }
            {props.page === 'student-rooms' &&
                <KotRouter
                    {...props}
                    key='KotRouter'
                    selectedLocationTag={selectedTag}
                    refreshParamsForUrlTranslation={refreshParamsForUrlTranslation}
                    pathname={pathname}
                    lang={lang}
                    path={path}
                    altLangUrls={altLangUrls}

                />
            }
            {props.page === 'student-life' &&
                <ArticleRouter
                    {...props}
                    key='ArticleRouter'
                    selectedArticleTagObject={selectedTag}
                    pathname={pathname}
                    lang={lang}
                    path={path}
                    altLangUrls={altLangUrls}
                    darkMode={darkMode}

                />
            }
        </PageWrapper>
    )
}

export default IndexRouter
