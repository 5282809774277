import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { useStyles } from './AuthentificationContainer_style'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import FilledButton from 'buttons/FilledButton/FilledButton'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import PasswordInput from 'inputs/PasswordInput/PasswordInput'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'

import AnalyticsHelper from 'assets/AnalyticsHelper'
import IconFactory from 'icons/IconFactory/IconFactory'
import LineButton from 'buttons/LineButton/LineButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import SessionAPI from 'api/SessionAPI'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'

const AuthentificationContainer = (props) => {
    const {
        handleChange,
        logInFacebook,
        logRequest,
        signUpRequest,
        state,
        setState
    } = props

    const [isLogin, setIsLogin] = useState(true)
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null
    })

    const classes = useStyles()
    const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID

    const reverseAuthPage = () => {
        setIsLogin(!isLogin)
    }

    const handleBlur = (event) => {
        SessionAPI.DoesUserEmailAlreadyExists({ email: event.target.value.toLowerCase() })
            .then((response) => {
                setUserVerifications(response.data.user_exist)
                setIsLogin(response.data.user_exist)
            })
    }

    const setUserVerifications = (userExist) => {
        if (isLogin && !userExist) {
            setFeedback({
                open: true,
                severity: 'info',
                message: <FormattedMessage id="landing_login_page.login_switch.no_account" />
            })
            setState({ ...state, signUpUserEmail: state.loginUserEmail })
        } else if (!isLogin && userExist) {
            setFeedback({
                open: true,
                severity: 'info',
                message: <FormattedMessage id="landing_login_page.login_switch.existing_account" />
            })
            setState({ ...state, loginUserEmail: state.signUpUserEmail })
        }
    }

    const closeFeedback = () => {
        setFeedback({ ...feedback, open: false })
    }

    const renderRememberMe = () => {
        return (
            <Box display='flex' alignItems='center'>
                <Checkbox
                    color='primary'
                    onClick={() => { setState({ ...state, rememberMe: !state.rememberMe }) }}
                    icon={<IconFactory icon='checkbox-false' />}
                    checkedIcon={<IconFactory icon='checkbox-true'/>}
                    className={classes.rememberMe}
                />
                <Typography variant='subtitle1' fontStyle='italic'>
                    <FormattedMessage id="landing_login_page.remember_me" />
                </Typography>
            </Box>
        )
    }

    const renderLoginForm = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h2' component='h1' color='primary' className={classes.typographyStyle}>
                        <FormattedMessage id="landing_login_page.login.title" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle2' component='h2'>
                        <FormattedMessage id="landing_login_page.email" />
                    </Typography>
                    <FormControl variant='outlined' fullWidth>
                        <TextField
                            required
                            type='email'
                            value={state.loginUserEmail}
                            name="loginUserEmail"
                            onChange={handleChange}
                            autoComplete='on'
                            error={state.loginError.email}
                            helperText={state.loginError.email}
                            onBlur={handleBlur}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle2' component='h2'>
                        <FormattedMessage id="landing_login_page.password" />
                    </Typography>
                    <PasswordInput
                        value={state.loginUserPassword}
                        name="loginUserPassword"
                        onChange={handleChange}
                        error={state.loginError.password}
                        helperText={state.loginError.password}
                    />
                </Grid>
                <Grid item xs={12}>
                    {renderRememberMe()}
                </Grid>
                <Grid item xs={12} className={classes.loginButtonContainer}>
                    <FilledButton
                        type='submit'
                        color="secondary"
                        name={<FormattedMessage id="landing_login_page.login.button" />}
                        className={classes.buttonStyle}
                    />
                    <RouteMapLink page="password/new" className={classes.forgotPasswordContent}>
                        <Typography variant='subtitle1' component='div' className={classes.typographyStyle}>
                            <FormattedMessage id="landing_login_page.forgot_password" />
                        </Typography>
                    </RouteMapLink>
                </Grid>
            </Grid>
        )
    }

    const renderRegisterForm = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h2' component='h1' color='primary' className={classes.typographyStyle}>
                        <FormattedMessage id="landing_login_page.sign_up.title" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle2' component='h2'>
                        <FormattedMessage id="landing_login_page.email" />
                    </Typography>
                    <FormControl variant='outlined' fullWidth={true}>
                        <TextField
                            required={true}
                            type={'text'}
                            value={state.signUpUserEmail}
                            name="signUpUserEmail"
                            onChange={handleChange}
                            autoComplete='on'
                            error={state.signUpErrors?.email || false}
                            helperText={(state.signUpErrors?.email && state.signUpErrors?.email[0]) || ''}
                            onBlur={handleBlur}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle2' component='h2'>
                        <FormattedMessage id="landing_login_page.new_password" />
                    </Typography>
                    <PasswordInput
                        value={state.signUpUserPassword}
                        name="signUpUserPassword"
                        onChange={handleChange}
                        error={state.signUpErrors?.password || false}
                        helperText={(state.signUpErrors?.password && state.signUpErrors?.password[0]) || ''}
                    />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        color='primary'
                        onClick={() => { setState({ ...state, termsAccepted: !state.termsAccepted }) }}
                        icon={<IconFactory icon='checkbox-false' />}
                        checkedIcon={<IconFactory icon='checkbox-true'/>}
                        className={classes.rememberMe}
                    />
                    <Typography variant='subtitle2' className={classes.conditionStyle}>
                        <FormattedMessage
                            id="landing_login_page.sign_up.website_deal"
                            values={{
                                conditionsLink: <span className={classes.textUnderline}>
                                    <RouteMapLink page="terms" target='_blank' style={{ color: 'var(--red)' }}>
                                        <FormattedMessage id="landing_login_page.sign_up.website_deal_link" />
                                    </RouteMapLink>
                                </span>
                            }}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.loginButtonContainer}>
                    <FilledButton
                        type='submit'
                        color="secondary"
                        className={classes.buttonStyle}
                        name={<FormattedMessage id="landing_login_page.sign_up.button" />}
                    />
                </Grid>
            </Grid>
        )
    }

    const renderSocialLogins = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='subtitle1' component='div' className={classes.typographyStyle}>
                        <FormattedMessage id='landing_login_page.login.socials_or'>
                            {socialsSeparationText => socialsSeparationText.toUpperCase()}
                        </FormattedMessage>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FacebookLogin
                        appId={FACEBOOK_APP_ID}
                        fields="name,email,picture"
                        callback={logInFacebook}
                        onClick={() => AnalyticsHelper.sendGTMEvent('facebook-login-click')}
                        disableMobileRedirect
                        render={renderProps => (
                            <LineButton
                                color="primary"
                                className={classes.buttonStyle}
                                startIcon={<IconFactory icon='facebook' />}
                                onClick={renderProps.onClick}
                                name={<FormattedMessage id="landing_login_page.login.with_facebook" />}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }

    return (
        <Card className={`${classes.cardContainer} ${props.disableShadow && classes.disableShadow}`} elevation={4}>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                closeFeedback={closeFeedback}
                severity={feedback.severity}
                anchor={{ vertical: 'top', horizontal: 'left' }}
            />
            <CardContent className={classes.cardContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {isLogin
                            ? <form onSubmit={logRequest}>
                                {renderLoginForm()}
                            </form>
                            : <form onSubmit={signUpRequest}>
                                {renderRegisterForm()}
                            </form>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {renderSocialLogins()}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.dividerStyle}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            underline='hover'
                            color='secondary'
                            className={classes.linkStyle}
                            onClick={reverseAuthPage}
                        >
                            <Typography variant='body2' className={classes.typographyStyle} color='secondary'>
                                {isLogin
                                    ? <FormattedMessage id='landing_login_page.no_account' />
                                    : <FormattedMessage id='landing_login_page.connect_with_account' />
                                }
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default AuthentificationContainer
