/* eslint-disable react/prop-types */
import React from 'react'

import RouteMap from 'assets/RouteMap.js'

import { Image, Transformation } from 'cloudinary-react'

import css from './employerbanner.scss'
import css2 from './employerbannerresponsive.scss'

import IconFactory from 'icons/IconFactory/IconFactory'

import { FormattedMessage } from 'react-intl'

import ShareAction from 'components/ShareAction/ShareAction'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './employerbanner_style'
import { optimiseSizeForMobile, EXTRA_LARGE_SIZE, SMALLER_SIZE } from 'assets/CloudinaryURLHelper'

class EmployerBanner extends React.Component {
    constructor (props) {
        super(props)

        this.uploaderRef = React.createRef()
        this.getNameFromCollection = this.getNameFromCollection.bind(this)
        this.getSummaryLabels = this.getSummaryLabels.bind(this)
        this.onMouseEnterBtnEditBanner = this.onMouseEnterBtnEditBanner.bind(this)
        this.onMouseLeaveBtnEditBanner = this.onMouseLeaveBtnEditBanner.bind(this)
        this.getSentenceForInfo = this.getSentenceForInfo.bind(this)
        this.defaultSummaryLabels = [
            [this.props.companyData.size, '', 'size']
        ]
        this.state = {
            editBannerButtonText: <FormattedMessage id="company_profile.employer_banner.adjust_photo" />,
            pictureId: this.props.companyBanner ? this.props.companyBanner : '',
            summaryLabels: ((this.props.companyData.featured_characteristics &&
                this.props.formParams)
                ? this.getSummaryLabels(this.props.companyData, this.props.companyData.featured_characteristics)
                : this.defaultSummaryLabels),
            companyData: this.props.companyData
        }
    }

    onMouseEnterBtnEditBanner () {
        this.setState({ editBannerButtonText: <FormattedMessage id="company_profile.employer_banner.recommended_photo " /> })
    }

    onMouseLeaveBtnEditBanner () {
        this.setState({ editBannerButtonText: <FormattedMessage id="company_profile.employer_banner.adjust_photo" /> })
    }

    getKeysFromValues (array, values, maxElems) {
        // les informations des tags sont les mots clés correspondants à l'information (exemple Kot à Projet = kot-project)
        // il faut afficher dans la langue de l'utilisateur l'information désiré
        var keysArray = []

        if (maxElems > values.length) {
            maxElems = values.length
        }
        for (var i = 0; i < maxElems; i++) {
            for (var s in array) {
                if (array[s][1] == values[i]) {
                    keysArray.push(array[s][0])
                }
            }
        }
        return keysArray
    }

    getSentenceForInfo (info, value) {
        switch (info) {
        case 'nbr_countries':
            if (parseInt(value) && parseInt(value) == 1) {
                return <FormattedMessage id="company_profile.employer_banner.nbr_countries" />
            }
            return <FormattedMessage id="company_profile.employer_banner.nbr_countries_plural" />
        case 'nbr_employees':
            return <FormattedMessage id="company_profile.employer_banner.nbr_employees" />
        case 'employees_average_ages':
            return <FormattedMessage id="company_profile.employer_banner.employees_average_ages" />
        case 'nbr_young_graduated_hired_per_year':
            return <FormattedMessage id="company_profile.employer_banner.nbr_young_graduated_hired_per_year" />
        case 'ceo_name':
            return <FormattedMessage id="company_profile.employer_banner.ceo_name" />
        case 'nbr_employees_below_thirthy':
            return <FormattedMessage id="company_profile.employer_banner.nbr_employees_below_thirthy" />
        case 'sales_revenues':
            return <FormattedMessage id="company_profile.employer_banner.sales_revenues" />
        }
    }

    getSummaryLabels (data, featured) {
        var labels = []
        var labelValue = ''
        const toAdd = {
            nbr_countries: <FormattedMessage id="company_profile.employer_banner.nbr_countries" />,
            nbr_employees: <FormattedMessage id="company_profile.employer_banner.nbr_employees" />,
            nbr_employees_below_thirthy: <FormattedMessage id="company_profile.employer_banner.nbr_employees_below_thirthy" />,
            employees_average_ages: <FormattedMessage id="company_profile.employer_banner.employees_average_ages" />,
            nbr_young_graduated_hired_per_year: <FormattedMessage id="company_profile.employer_banner.nbr_young_graduated_hired_per_year" />,
            ceo_name: <FormattedMessage id="company_profile.employer_banner.ceo_name" />,
            size: '',
            sales_revenues: <FormattedMessage id="company_profile.employer_banner.sales_revenues" />
        }

        var arrayLabelsKeys = this.props.formParams.size.concat(
            this.props.formParams.nbrEmployees,
            this.props.formParams.averageAges,
            this.props.formParams.nbrYoungGraduatedPerYear,
            this.props.formParams.nbrEmployeesBelow30
        )

        for (var i in featured) {
            labelValue = this.getKeysFromValues(arrayLabelsKeys, [data[featured[i]]], 1)[0]

            if (!labelValue || Number.isInteger(data[featured[i]])) {
                labelValue = data[featured[i]]
            }

            labels.push([labelValue, this.getSentenceForInfo(featured[i], labelValue), featured[i]])
        }
        return labels
    }

    getNameFromCollection (labelValue, collection) {
        const labelObj = this.props.formParams[collection].find((obj) => {
            return obj.value === labelValue
        })
        if (labelObj) {
            return labelObj.name
        }
        return labelValue
    }

    displaySummaryLabels () {
        const buffer = []
        let counter = 0
        const { classes } = this.props
        const summaryLabels = this.state.summaryLabels.length > 0 ? this.state.summaryLabels : this.defaultSummaryLabels
        for (var i = 0; i < (summaryLabels.length); i++) {
            var size = summaryLabels.length
            var labelValue = i < size ? summaryLabels[i][0] : ''
            var label = i < size ? summaryLabels[i][1] : (this.props.edit ? <FormattedMessage id="company_profile.employer_banner.key_figures"/> : <FormattedMessage id="company_profile.employer_banner.information_not_save"/>)
            if (summaryLabels[i][2] === 'size') {
                labelValue = this.getNameFromCollection(labelValue, 'size')
            } else if (summaryLabels[i][2] === 'employees_average_ages') {
                labelValue = this.getNameFromCollection(labelValue, 'averageAges')
            } else if (summaryLabels[i][2] === 'nbr_employees') {
                labelValue = this.getNameFromCollection(labelValue, 'nbrEmployees')
            } else if (summaryLabels[i][2] === 'nbr_young_graduated_hired_per_year') {
                labelValue = this.getNameFromCollection(labelValue, 'nbrYoungGraduatedPerYear')
            } else if (summaryLabels[i][2] === 'nbr_employees_below_thirthy') {
                labelValue = this.getNameFromCollection(labelValue, 'nbrEmployeesBelow30')
            } else if (summaryLabels[i][2] === 'sales_revenues') {
                labelValue = String(labelValue).replace(/\B(?!\,\d*)(?=(\d{3})+(?!\d))/g, '.')
            }
            if (labelValue) {
                buffer.push(
                    <div className={classes.employerSummaryItem} key={'summary-label-' + counter}>
                        <div className={Number.isInteger(labelValue) ? 'number' : 'string'}>
                            {labelValue}
                        </div>
                        {label}
                    </div>
                )
                counter++
            } else {
                return (
                    <div className={classes.employerSummaryItem}>
                        <div className='string'>
                            {this.getNameFromCollection(this.props.companyData.size, 'size')}
                        </div>
                    </div>
                )
            }
        }

        return buffer
    }

    render () {
        const style = { backgroundImage: `url(${this.props.companyPicture})` }
        const { classes } = this.props
        return (
            <div className={classes.employerBannerContainer}>
                <div className={classes.employerBannerImageContainer}>

                    {this.state.pictureId
                        ? <div className={classes.employerHeaderContainer}>
                            <Image
                                alt={`${this.props.companyName} banner`}
                                cloudName="studentbe"
                                publicId={this.state.pictureId}
                            >
                                <Transformation flags="progressive" fetchFormat="auto" width={optimiseSizeForMobile(EXTRA_LARGE_SIZE)} quality="auto:best" crop="fill" />
                            </Image>
                        </div>
                        : <div className={classes.employerHeaderNoPicture} style={style}>$</div>
                    }
                </div>
                <div className={classes.iconContainer}>
                    <ShareAction
                        infos={{
                            title: this.props.companyName,
                            url: RouteMap.Show('company', this.props.companyData.url),
                            type: 'company'
                        }}
                    />
                    {
                        (this.props.user && this.props.user.admin) &&
                        <Tooltip title={<FormattedMessage id="company_profile.employer_banner.edit_ads"/>}>
                            <IconButton onClick={() => {
                                window.location.href = `/${RouteMap.GetLocaleFromUrl()}/admin/company/${this.props.company_id}/react_edit`
                            }}>
                                <IconFactory
                                    icon='edit'
                                    style={{ color: 'var(--black)' }}
                                />
                            </IconButton>
                        </Tooltip>
                    }
                </div>
                <Hidden smDown>
                    <div className={classes.employerSummaryContainer}>
                        <div className={classes.employerBanner}>
                            <div className={classes.employerSummary}>
                                <div className={classes.employerSummaryLogoContainer}>
                                    {this.state.companyData.logo_cloudinary_key
                                        ? <FormattedMessage id='company_profile.employer_banner.logo_alt' values={{ companyName: this.props.companyName }}>
                                            {altLogo =>
                                                <Image
                                                    alt={altLogo}
                                                    cloudName="studentbe"
                                                    publicId={this.state.companyData.logo_cloudinary_key}
                                                >
                                                    <Transformation flags="progressive" fetchFormat="auto" width={SMALLER_SIZE} quality="auto:good" crop="fill" />
                                                </Image>
                                            }
                                        </FormattedMessage>
                                        : (this.props.edit ? <FormattedMessage id="company_profile.employer_banner.download_logo" /> : <FormattedMessage id="company_profile.employer_banner.no_logo" />)
                                    }
                                </div>
                                {this.displaySummaryLabels()}
                            </div>

                        </div>
                    </div>
                </Hidden>
            </div>
        )
    }
}

EmployerBanner.defaultProps = {
    companyPicture: 'https://images.pexels.com/photos/2451566/pexels-photo-2451566.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'
}

export default withStyles(useStyles)(EmployerBanner)
