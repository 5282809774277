
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import ExperienceHelper from 'assets/ExperienceHelper'

import { FormattedMessage } from 'react-intl'

import DateHandler from 'assets/DateHandler'
import RouteMap from 'assets/RouteMap'
import URLParamsHandler from 'assets/URLParamsHandler'
import UserAPI from 'api/UserAPI'

import StudentRegisterView from './StudentRegisterView'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import {
    EBOOK_DOWNLOAD, KOT_SHOW, JOB_APPLICATION,
    JOB_FAIRS, PROMOTION, MY_BS_REGISTRATION
} from 'assets/AuthentificationHelper'

const StudentRegister = (props) => {
    // Only ExperienceProfile information for register with 0
    // Full Register funnel with 2
    const totalSteps = 0

    const steps = [
        <FormattedMessage id="landing_login_page.sign_up.stepper.my_studies" key="formatted-message-1" />
    ]
    const [activeStep, setActiveStep] = useState(0)
    const [studentFormation, setStudentFormation] = useState(ExperienceHelper.InitializeExperiences([]))
    const [studentLanguage] = useState([])
    const [student, setStudent] = useState({})
    const [selectedDate] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        AnalyticsHelper.sendGTMEvent('registration-start', {
            user_type: 'student',
            language: RouteMap.GetLocaleFromUrl()
        })
    }, [])

    const updateProfileInfo = async () => {
        const formationArray = []
        studentFormation.forEach((item, key) => {
            const formation = { ...item }

            formation.field_of_study = formation.field_of_study ? formation.field_of_study.value : ''
            formation.school = formation.school ? formation.school.name : ''
            formation.start_year = formation.start_year ? new Date(formation.start_year).getFullYear() : null
            formation.end_year = formation.end_year ? new Date(formation.end_year).getFullYear() : null

            formationArray.push(formation)
        })

        studentLanguage.forEach(
            (langue, key) => {
                if (langue.name === '' && langue.skill_weight !== '') {
                    studentLanguage[key].deleted = true
                }
            }
        )
        const profiledata = {
            first_name: student.first_name,
            last_name: student.last_name,
            gender: student.gender,
            phone_number: student.phoneNumber,
            street: student.street,
            city: student.city,
            zip: student.zip,
            country: student.country,
            birthdate: selectedDate ? DateHandler.ToBasicFormat(selectedDate) : null,
            car: student.car,
            bike: student.bike,
            driving_licence: student.drivingLicence,
            experiences: formationArray,
            EN: student.EN,
            FR: student.FR,
            NL: student.NL,
            other_languages: studentLanguage,
            register_step: activeStep,
            origin: props.origin
        }

        return UserAPI.UserEditMyProfile(profiledata)
    }

    const sendEventFromOrigin = () => {
        AnalyticsHelper.sendGTMEvent('registration-success', {
            user_type: 'student',
            language: RouteMap.GetLocaleFromUrl()
        })
        // Retrieve experience with higher end_year to get latest and/or current
        // experience / formation
        const lastFormation = studentFormation.reduce((acc, exp) => {
            return (acc = acc.end_year > exp.end_year ? acc : exp)
        })
        AnalyticsHelper.sendGTMEvent(
            'Create Account Completed - Student',
            AnalyticsHelper.constructRegisterStudentAnalyticsAttributes(
                { // Construct Student object
                    id: props.userID,
                    type: 'student',
                    education_type: lastFormation.study_cycle,
                    background_type: lastFormation.study_type,
                    study_domain: lastFormation?.field_of_study?.value || lastFormation.other_field_of_study || undefined,
                    school_name: lastFormation?.school?.name || lastFormation?.other_school,
                    school_location: lastFormation?.school?.location
                },
                props.loginType,
                props.origin
            ))
        if (props.origin === EBOOK_DOWNLOAD) {
            AnalyticsHelper.sendGTMEvent('ebook-register-success')
        }
    }

    const hasAfterSignActionFromOrigin = () => {
        return props.origin === JOB_APPLICATION ||
            props.origin === EBOOK_DOWNLOAD ||
            props.origin === KOT_SHOW ||
            props.origin === JOB_FAIRS ||
            props.origin === PROMOTION ||
            props.origin === MY_BS_REGISTRATION
    }

    const handleRedirection = () => {
        const urlParams = URLParamsHandler.parseURLParams()
        if (urlParams.redirect) {
            URLParamsHandler.redirectWithURL()
        } else if (hasAfterSignActionFromOrigin()) {
            props.afterSignInAction()
        } else {
            window.location.href = RouteMap.Page('users/dashboard')
        }
    }

    const handleNext = (event) => {
        event.preventDefault()
        setLoading(true)
        updateProfileInfo().then(response => {
            if (activeStep === totalSteps) {
                sendEventFromOrigin()
                AnalyticsHelper.sendAnalyticsEvent('identify', { user_id: response.data.user.id })
                AnalyticsHelper.sendAnalyticsEvent('track', {
                    user_id: response.data.user.id,
                    event_name: 'Signup Completed',
                    custom_props: { origin: props.origin }
                }).finally(_ => handleRedirection())
            } else {
                setLoading(false)
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
                window.scrollTo(0, 0)
            }
        })
    }

    return <StudentRegisterView
        handleNext={handleNext}
        steps={steps}
        activeStep={activeStep}
        student={student}
        setStudent={setStudent}
        studentFormation={studentFormation}
        setStudentFormation={setStudentFormation}
        loading={loading}
    />
}

export default StudentRegister
