import React from 'react'
import { FormattedMessage } from 'react-intl'
import isEmpty from 'lodash.isempty'

import CollectionHandler from 'assets/CollectionHandler'
import RegexHelper from 'assets/RegexHelper'
import {
    COMPANY_STEP,
    JOB_STEP,
    CANDIDATE_STEP,
    OFFER_STEP,
    SETTINGS_STEP,
    PUBLICATION_STEP,
    FIRST_JOB,
    INTERNSHIP,
    ESSENTIAL_STEP,
    EXTRA_STEP
} from "../../settings"

const validateStep = (step, jobFormData) => {
    let formErrors = {}
    if (step === COMPANY_STEP) {
        formErrors = handleCompanyFormValidations(jobFormData)
    } else if (step === JOB_STEP) {
        formErrors = handleJobFormValidations(jobFormData)
    } else if (step === CANDIDATE_STEP) {
        formErrors = handleCandidateFormValidations(jobFormData)
    } else if (step === OFFER_STEP) {
        formErrors = handleOfferFormValidations(jobFormData)
    } else if (step === ESSENTIAL_STEP) {
        formErrors = handleStudentJobEssentialFormValidations(jobFormData)
    } else if (step === EXTRA_STEP) {
        formErrors = handleStudentJobExtraFormValidations(jobFormData)
    } else if (step === SETTINGS_STEP) {
        formErrors = handleSettingsFormValidations(jobFormData)
    } else if (step === PUBLICATION_STEP) {
        formErrors = { is_always_invalid: true }
    }
    const isValid = isEmpty(formErrors)
    return [isValid, formErrors]
}

const isTextEmpty = (text) => {
    return (!text || text === '' || text === '<p><br></p>')
}

const handleCompanyFormValidations = (jobFormData) => {
    let errors = {}
    if (isTextEmpty(jobFormData.companyName)) errors.companyName = <FormattedMessage id="job_form.step_1.errors.company_name" />
    if (isEmpty(jobFormData.companySize)) errors.companySize = <FormattedMessage id="job_form.step_1.errors.company_size" />
    if (isEmpty(jobFormData.companySector)) errors.companySector = <FormattedMessage id="job_form.step_1.errors.sector" />
    if (isTextEmpty(jobFormData.companyDescription)) errors.companyDescription = <FormattedMessage id="job_form.step_1.errors.company_description" />
    if (['<p><br></p>'].includes(jobFormData.companyDescription)) errors.companyDescription = <FormattedMessage id="job_form.step_1.errors.company_description" />
    if (jobFormData.jobType != FIRST_JOB) {
        if (RegexHelper.telephoneNumberRegex.test(jobFormData.companyDescription)) errors.companyDescription = <FormattedMessage id="job_form.step_2.errors.telephone_number_present" />
        if (RegexHelper.emailPresentInTextRegex.test(jobFormData.companyDescription)) errors.companyDescription = <FormattedMessage id="job_form.step_2.errors.email_present" />
        if (RegexHelper.urlRegex.test(jobFormData.companyDescription)) errors.companyDescription = <FormattedMessage id="job_form.step_2.errors.url_present" />
    }

    return errors
}

const handleJobFormValidations = (jobFormData) => {
    let errors = {}
    if (isTextEmpty(jobFormData.jobTitle)) errors.jobTitle = <FormattedMessage id="job_form.step_2.errors.title" />
    if (isEmpty(jobFormData.jobRegime)) errors.jobRegime = <FormattedMessage id="job_form.step_2.errors.regime" />
    if (isTextEmpty(jobFormData.jobZip)) errors.jobZip = <FormattedMessage id="landing_login_page.errors.city" />
    if (isTextEmpty(jobFormData.jobCity)) errors.jobCity = <FormattedMessage id="landing_login_page.errors.city" />
    if (isTextEmpty(jobFormData.jobCountry)) errors.jobCountry = <FormattedMessage id="landing_login_page.errors.city" />
    if (isTextEmpty(jobFormData.jobDescription)) errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.description" />
    if (['', '<p><br></p>'].includes(jobFormData.jobDescription)) errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.description" />
    if (isEmpty(jobFormData.jobDepartment)) errors.jobDepartment = <FormattedMessage id="job_form.step_2.errors.department" />

    if (jobFormData.jobType != FIRST_JOB) {
        if (RegexHelper.telephoneNumberRegex.test(jobFormData.jobDescription)) errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.telephone_number_present" />
        if (RegexHelper.emailPresentInTextRegex.test(jobFormData.jobDescription)) errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.email_present" />
        if (RegexHelper.urlRegex.test(jobFormData.jobDescription)) errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.url_present" />
    }
    if (jobFormData.jobType === FIRST_JOB) {
        if (isEmpty(jobFormData.jobContractType)) errors.jobContractType = <FormattedMessage id="job_form.step_2.errors.contract_type" />
    }
    if (jobFormData.jobType === INTERNSHIP) {
        if (isTextEmpty(jobFormData.internshipLength)) { errors.internshipLength = <FormattedMessage id="job_form.step_2.errors.length" /> }
    }

    return errors
}

const handleCandidateFormValidations = (jobFormData) => {
    let errors = {}
    if (isTextEmpty(jobFormData.candidateSearchedProfile)) errors.candidateSearchedProfile = <FormattedMessage id="job_form.step_3.errors.searched_profile" />
    if (RegexHelper.telephoneNumberRegex.test(jobFormData.candidateSearchedProfile)) errors.candidateSearchedProfile = <FormattedMessage id="job_form.step_2.errors.telephone_number_present" />
    if (RegexHelper.emailPresentInTextRegex.test(jobFormData.candidateSearchedProfile)) errors.candidateSearchedProfile = <FormattedMessage id="job_form.step_2.errors.email_present" />
    if (RegexHelper.urlRegex.test(jobFormData.candidateSearchedProfile)) errors.candidateSearchedProfile = <FormattedMessage id="job_form.step_2.errors.url_present" />
    if (isEmpty(jobFormData.candidateStudyDomains)) errors.candidateStudyDomains = <FormattedMessage id="job_form.step_3.errors.study_domain" />

    return errors
}

const handleOfferFormValidations = (jobFormData) => {
    let errors = {}
    if (isTextEmpty(jobFormData.weOffer)) errors.weOffer = <FormattedMessage id="job_form.step_2.errors.we_offer" />

    return errors
}

const handleStudentJobEssentialFormValidations = (jobFormData) => {
    let errors = {}
    if (isTextEmpty(jobFormData.companyName)) errors.companyName = <FormattedMessage id="job_form.step_1.errors.company_name" />
    if (isEmpty(jobFormData.companySize)) errors.companySize = <FormattedMessage id="job_form.step_1.errors.company_size" />
    if (isEmpty(jobFormData.companySector)) errors.companySector = <FormattedMessage id="job_form.step_1.errors.sector" />
    if (isTextEmpty(jobFormData.jobTitle)) errors.jobTitle = <FormattedMessage id="job_form.step_2.errors.title" />
    if (isEmpty(jobFormData.studentJobSchedule)) errors.studentJobSchedule = <FormattedMessage id="job_form.step_2.errors.regime" />
    if (isTextEmpty(jobFormData.jobZip)) errors.jobZip = <FormattedMessage id="landing_login_page.errors.city" />
    if (isTextEmpty(jobFormData.jobCity)) errors.jobCity = <FormattedMessage id="landing_login_page.errors.city" />
    if (isTextEmpty(jobFormData.jobCountry)) errors.jobCountry = <FormattedMessage id="landing_login_page.errors.city" />
    if (isTextEmpty(jobFormData.jobDescription)) {
        errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.description" />
    } else {
        if (RegexHelper.telephoneNumberRegex.test(jobFormData.jobDescription)) errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.telephone_number_present" />
        if (RegexHelper.emailPresentInTextRegex.test(jobFormData.jobDescription)) errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.email_present" />
        if (RegexHelper.urlRegex.test(jobFormData.jobDescription)) errors.jobDescription = <FormattedMessage id="job_form.step_2.errors.url_present" />
    }

    return errors
}

const handleStudentJobExtraFormValidations = (jobFormData) => {
    let errors = {}
    if (jobFormData.studentJobStartDate) {
        const deadlineDate = new Date(jobFormData.studentJobStartDate)
        if (deadlineDate < new Date()) errors.studentJobStartDate = <FormattedMessage id="job_form.step_4.errors.deadline_before_today" />
    }
    if (!isTextEmpty(jobFormData.companyDescription)) {
        if (RegexHelper.telephoneNumberRegex.test(jobFormData.companyDescription)) errors.companyDescription = <FormattedMessage id="job_form.step_2.errors.telephone_number_present" />
        if (RegexHelper.emailPresentInTextRegex.test(jobFormData.companyDescription)) errors.companyDescription = <FormattedMessage id="job_form.step_2.errors.email_present" />
        if (RegexHelper.urlRegex.test(jobFormData.companyDescription)) errors.companyDescription = <FormattedMessage id="job_form.step_2.errors.url_present" />
    }
    if (!isTextEmpty(jobFormData.candidateSearchedProfile)) {
        if (RegexHelper.telephoneNumberRegex.test(jobFormData.candidateSearchedProfile)) errors.candidateSearchedProfile = <FormattedMessage id="job_form.step_2.errors.telephone_number_present" />
        if (RegexHelper.emailPresentInTextRegex.test(jobFormData.candidateSearchedProfile)) errors.candidateSearchedProfile = <FormattedMessage id="job_form.step_2.errors.email_present" />
        if (RegexHelper.urlRegex.test(jobFormData.candidateSearchedProfile)) errors.candidateSearchedProfile = <FormattedMessage id="job_form.step_2.errors.url_present" />
    }
    if (!isTextEmpty(jobFormData.weOffer)) {
        if (RegexHelper.telephoneNumberRegex.test(jobFormData.weOffer)) errors.weOffer = <FormattedMessage id="job_form.step_2.errors.telephone_number_present" />
        if (RegexHelper.emailPresentInTextRegex.test(jobFormData.weOffer)) errors.weOffer = <FormattedMessage id="job_form.step_2.errors.email_present" />
        if (RegexHelper.urlRegex.test(jobFormData.weOffer)) errors.weOffer = <FormattedMessage id="job_form.step_2.errors.url_present" />
    }

    return errors
}

const handleSettingsFormValidations = (jobFormData) => {
    let errors = {}
    if (jobFormData.jobType === FIRST_JOB) {
        if (isNaN(jobFormData.nbrOfCandidates)) errors.nbrOfCandidates = <FormattedMessage id="jobcreationfunnel.errors.must_be_a_number" />
        if (isEmpty(jobFormData.nbrOfCandidates) && jobFormData.nbrOfCandidates < 1) errors.nbrOfCandidates = <FormattedMessage id="job_form.step_2.errors.number_of_people_sought" />
    }
    if (!(jobFormData.nl || jobFormData.fr)) errors.language = <FormattedMessage id="job_form.step_4.errors.language" />
    if (jobFormData.applicationType === 'email') {
        if (isTextEmpty(jobFormData.applicationEmail)) errors.applicationEmail = <FormattedMessage id="job_form.step_4.errors.application_email" />
        if (!RegexHelper.applicationEmailRegex.test(jobFormData.applicationEmail)) {
            errors.applicationEmail = <FormattedMessage id="job_form.step_4.errors.application_email_regex" />
        }
    }
    if (jobFormData.applicationType === 'url' && isTextEmpty(jobFormData.applicationUrl)) errors.applicationUrl = <FormattedMessage id="job_form.step_4.errors.application_url" />
    if (jobFormData.deadlineToApply) {
        const deadlineDate = new Date(jobFormData.deadlineToApply)
        if (deadlineDate < new Date()) errors.deadlineToApply = <FormattedMessage id="job_form.step_4.errors.deadline_before_today" />
        if (!jobFormData.user.gold_licence && deadlineDate > new Date().setMonth(new Date().getMonth() + CollectionHandler.Get('jobOfferDuration').first_job)) {
            errors.deadlineToApply = <FormattedMessage id="job_form.step_4.errors.deadline_too_far" />
        }
    }

    return errors
}

export default validateStep
