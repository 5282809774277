import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './SEOBlock_style'
import IconFactory from 'icons/IconFactory/IconFactory'

const SEOBlock = (props) => {
    const classes = useStyles()
    const { formatMessage } = props.intl
    const { selectedLocationTag, type } = props
    const [showContent, setShowContent] = useState(false)

    const toggleAccordion = () => { setShowContent(!showContent) }

    const getTitleId = () => {
        if (!selectedLocationTag) {
            return `seo_block.title.${type}.default`
        } else {
            if (selectedLocationTag.type === 'quickFilter') {
                return `seo_block.title.${type}.quick_filter.${selectedLocationTag.value}`
            } else if (selectedLocationTag.type === 'studyDomain') {
                return `seo_block.title.${type}.study_domain.${selectedLocationTag.value}`
            } else if (selectedLocationTag.type === 'category') {
                return `seo_block.title.${type}.category.${selectedLocationTag.value}`
            } else if (selectedLocationTag.type === 'location') {
                return `seo_block.title.${type}.location`
            }
        }
    }

    const getTextId = () => {
        if (!selectedLocationTag) {
            return `index_seo_text.${type}.default`
        } else {
            if (selectedLocationTag.type === 'quickFilter') {
                return `index_seo_text.${type}.quick_filter.${selectedLocationTag.value}`
            } else if (selectedLocationTag.type === 'studyDomain') {
                return `index_seo_text.${type}.study_domain.${selectedLocationTag.value}`
            } else if (selectedLocationTag.type === 'location') {
                return `index_seo_text.${type}.${selectedLocationTag.value}_${selectedLocationTag.value_name}`
            } else if (selectedLocationTag.type === 'category') {
                return `index_seo_text.${type}.category.${selectedLocationTag.value}`
            }
        }
    }

    const getSplitTextOnWords = (text) => {
        if (showContent) return text

        const words = text.split(/[\s]/) // Split the text into words
        const totalWords = words.length
        let part1 = ''

        // Calculate where to split
        const splitIndex = Math.floor(totalWords * 0.45)

        // Reconstruct the parts
        for (let i = 0; i < totalWords; i++) {
            const word = words[i] + (i < totalWords - 1 ? ' ' : '') // Add punctuation
            if (i < splitIndex) {
                part1 += word
            }
        }
        part1 = part1.slice(0, -1) + '...'

        return part1
    }

    const titleId = getTitleId()
    const textId = getTextId()

    if (!titleId || !textId) return null
    if (formatMessage({ id: titleId }) === titleId || formatMessage({ id: textId }) === textId) {
        return null
    }

    return (
        <Box className={classes.seoBlockContainer}>
            <Container maxWidth="lg">
                <Box className={classes.seoTextContainer}>
                    <Accordion className={classes.accordion} expanded onChange={toggleAccordion}>
                        <AccordionSummary expandIcon={<IconFactory icon={showContent ? 'down' : 'up'} />}>
                            <Box className={classes.accordionTitle}>
                                <Typography variant="h3">
                                    {formatMessage({ id: titleId }, { city: selectedLocationTag?.name })}
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails onClick={toggleAccordion} className={classes.accordionDetails}>
                            <Typography
                                className={classes.accordionText}
                                dangerouslySetInnerHTML={{ __html: getSplitTextOnWords(formatMessage({ id: textId })) }}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Container>
        </Box>
    )
}

export default injectIntl(SEOBlock)
