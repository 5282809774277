import React from 'react'

import { Image, Transformation } from 'cloudinary-react'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import { withStyles } from '@material-ui/core/styles'

import LineButton from 'buttons/LineButton/LineButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import { FormattedMessage } from 'react-intl'
import { useStyles } from './companyprofilebanner_style'
import { SMALLER_SIZE } from 'assets/CloudinaryURLHelper'

class CompanyProfileBanner extends React.Component {
    render () {
        const { classes } = this.props
        return (
            <Container maxWidth="lg" className={classes.companyProfileBannerContainer}>
                <Box display="flex" alignItems="center">
                    <Hidden mdDown>
                        {this.props.companyLogoCloudinaryKey &&
                            <Box className={classes.logoContainer}>
                                <Image
                                    className="picture"
                                    cloudName="studentbe"
                                    alt={`${this.props.companyName} ${this.props.companyLogoCloudinaryKey} logo`}
                                    publicId={this.props.companyLogoCloudinaryKey}
                                >
                                    <Transformation flags="progressive" fetchFormat="auto" width={SMALLER_SIZE} quality="auto:best" crop="fill" />
                                </Image>
                            </Box>
                        }
                    </Hidden>
                    <Box className={classes.companyName}>
                        <Typography
                            variant='body1'
                            color='primary'
                        >
                            <FormattedMessage id="company_profile.company_profile_banner.title" />
                        </Typography>
                        <Typography
                            variant='h1'
                            color='primary'
                        >
                            {this.props.companyName}
                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.buttonContainer} >
                    <RouteMapLink page='users/company_profiles/edit'>
                        <LineButton
                            name={<FormattedMessage id="company_profile.company_profile_banner.modify_page_button" />}
                        />
                    </RouteMapLink>
                    <RouteMapLink
                        showItemType='company'
                        showItemUrl={this.props.companyData.url}
                    >
                        <LineButton
                            name={<FormattedMessage id="company_profile.company_profile_banner.button" />}
                        />
                    </RouteMapLink>
                </Box>
            </Container>
        )
    }
}

export default withStyles(useStyles)(CompanyProfileBanner)
